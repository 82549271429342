#createapp {
    .AnimationCreator {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 75px 0;

        & > div {
            text-align: center;

            .formats {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                gap: 40px;
            }

            button {
                color: #fff;
                font-weight: bold;
                margin-top: 40px;
                margin-bottom: 40px;

                svg path {
                    fill: #fff;
                }
            }

            h3 {
                margin-bottom: 20px;
                opacity: 0.7;
            }

            .videos {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                gap: 10px;

                .video {
                    width: 170px;
                    cursor: pointer;

                    span {
                        display: block;
                        font-size: 0.5em;
                    }
                }
            }
        }
    }
}
