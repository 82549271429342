#createapp {
    .cutouts-detail {
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .cutouts-detail-wrapper {
        flex: 1;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        overflow-y: auto;
        overflow-x: hidden;

        .cutout-detail-images {
            flex: 0 0 25%;
            display: flex;
            flex-direction: column;

            ul {
                //  overflow-y: auto;
                //   overflow-x: hidden;

                li {
                    span:first-child {
                        display: flex;
                        gap: 12px;
                        align-items: center;
                    }

                    img {
                        width: 50px;
                        height: auto;
                        display: block;
                    }
                }
            }
        }

        .cutout-detail-angles {
            flex: 0 0 50%;

            .noangles {
                justify-content: center;
                color: rgba(255, 255, 255, 0.6);
            }
        }

    }

    .cutouts-detail-actions {
        padding: 30px 20px;
        display: flex;
        justify-content: flex-end;
        flex: 0;
        border-top: 1px solid rgba(255, 255, 255, 0.26);
        color: #FFF;

        >div {
            display: flex;
            flex: 0 0 50%;
            gap: 12px;
        }

        .plan {
            display: flex;
            justify-content: stretch;
            gap: 10px;
            flex-direction: column;

            label {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                display: block;
            }

            select {
                border-radius: 2px;
                border: 1px solid rgba(255, 255, 255, 0.26);
                background: #1A1A1A;
                padding: 10px 16px;
                width: 100%;
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;

                &:focus {
                    outline: none;
                }
            }
        }

        .render,
        .preview {
            flex: 1;
            color: #000;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            text-transform: uppercase;
            background-color: #fff;
            display: inline-block;
            padding: 9px;
            transition: all .25s ease-in-out;
        }

        .preview {
            color: #fff;
            background-color: #1A1A1A;
            border: 1px solid #fff;
        }
    }


    .info-list {
        &.cutout-detail-list {
            >ul {
                >li {
                    &.cutout-detail-list-category {
                        margin-bottom: 10px;
                        color: #FFF;
                    }

                    .checkbox {
                        >label {
                            >div {
                                >span {
                                    &:first-child {
                                        margin-right: 20px;
                                    }
                                }
                            }
                        }
                    }

                    .cutout-angles,
                    .cutout-templatetypes {
                        padding-top: 30px;
                        padding-bottom: 30px;
                        display: flex;
                        justify-content: flex-start;
                        gap: 24px;

                        .cutout-angle,
                        .cutout-templatetype {
                            padding: 35px 25px;
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            position: relative;
                            transition: all .25s ease-in-out;
                            border: 1px solid rgba(255, 255, 255, 0.6);

                            .checkbox {
                                label {
                                    .check {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        border-top: 0 none;
                                        border-top-left-radius: 0;
                                        border-bottom-left-radius: 0;
                                        border-top-right-radius: 0;
                                        border-left: 0 none;
                                    }

                                    >div {
                                        display: flex;
                                        align-items: center;
                                        gap: 24px;

                                        span {
                                            svg {
                                                position: relative !important;
                                                max-width: 59px;
                                                max-height: 52px;
                                                width: 100%;
                                                height: auto;

                                                path {
                                                    transition: all .25s ease-in-out;
                                                    stroke: rgba(255, 255, 255, 0.6);
                                                }
                                            }
                                        }

                                        p {
                                            display: flex;
                                            flex-direction: column;
                                        }
                                    }
                                }
                            }

                            &.checked,
                            &:hover {
                                border: 1px solid #FFF;

                                label {
                                    >div {
                                        span {
                                            color: #FFF !important;

                                            svg {
                                                position: relative !important;

                                                path {
                                                    stroke: #FFF !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .cutout-templatetype {
                            .checkbox {
                                label {
                                    >div {
                                        span {
                                            svg {
                                                path {
                                                    fill: rgba(255, 255, 255, 0.6);
                                                }
                                            }
                                        }
                                    }
                                }

                            }

                            &.checked,
                            &:hover {
                                label {
                                    >div {
                                        span {
                                            svg {
                                                path {
                                                    fill: #FFF !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.cutout-detail-angles {
    ul {
        li {

            &:nth-child(2),
            &:nth-child(3) {
                border-top: 0 !important;
            }
        }
    }
}