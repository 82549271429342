#createapp {
    .moodboard-container {
        display: flex;
        width: 100%;
        height: 100%;


        .moodboard-overlay {
            position: fixed;
            background: #1A1A1A;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 10;
        }

        .moodboard-holder {
            overflow-y: scroll;
            overflow-x: hidden;
            min-height: 0;
            flex-grow: 1;
            padding: 10px;
        }

        .moodboard {
            .react-grid-item.react-grid-placeholder {
                background: green;
            }
            .rendering {
                border-radius: 2px;
                border: 1px solid #c7c7c7;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 10;
                padding: 10px;

                img {
                    max-width: 100%;
                    object-fit: cover;
                }
            }

            .item {
                border-radius: 2px;
                border: 1px solid #c7c7c7;
                background: #fff;
                cursor: pointer;
                position: relative;
                padding: 26px;
                cursor: move;
                transition: all ease-in-out 0.25s;
                outline: 1px solid transparent;

                &.hover {
                    border-color: #000;
                    outline: 1px solid #000;
                }

                &.hover-parent {
                    .image {
                        img {
                            opacity: 0.25;
                        }
                    }
                }

                &.required {
                    border-color: #F00;
                }

                &.offbrand {
                    opacity: 0.5;
                }

                .buttons {
                    position: absolute;
                    bottom: 10px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: #000;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: row;
                    opacity: 0;
                    transition: opacity ease-in-out 0.25s;

                    button {
                        padding: 10px;
                        opacity: 0.7;
                        transition: opacity ease-in-out 0.25s;

                        svg {
                            color: #fff;
                            margin: 0;
                        }

                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                &:hover {
                    .buttons {
                        opacity: 1;
                    }
                }

                .label {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    padding: 2px 8px;
                    border-radius: 14px;
                    border: 1px solid #c7c7c7;
                    color: #c7c7c7;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    background-color: #fff;
                    z-index: 2;
                }

                .name {
                    position: absolute;
                    bottom: 5px;
                    left: 5px;
                    right: 5px;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-align: center;
                    color: #888;
                }

                &.changed {
                    .label {
                        background-color: lighten($color: #B4CDC2, $amount: 15);
                        border: 1px solid darken($color: #c7c7c7, $amount: 15);
                        color: darken($color: #c7c7c7, $amount: 15);
                    }
                }

                .empty {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    padding: 2px 8px;
                    border-radius: 14px;
                    border: 1px solid #F00;
                    color: #F00;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    background-color: lighten($color: #F00, $amount: 45);
                    z-index: 2;
                }

                .offbrandwarning {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    padding: 2px 8px;
                    border-radius: 14px;
                    border: 1px solid #F00;
                    color: #F00;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    background-color: lighten($color: #F00, $amount: 45);
                    z-index: 2;
                }

                .image {
                    height: 100%;
                    width: 100%;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        -webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.6));
                        -moz-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.6));
                        -ms-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.6));
                        -o-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.6));
                        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.6));
                        transition: opacity 0.25s ease-in-out;

                        &.hover {
                            opacity: 1;
                        }
                    }

                    span {
                        border-radius: 4px;
                        border: 1px solid #c7c7c7;
                        padding: 5px;
                        margin: 2px;
                        display: inline-block;
                    }

                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    span {
                        text-align: center;
                        font-size: 12px;
                    }

                    &.multiple {
                        display: grid;
                        grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
                        grid-template-rows: repeat(auto-fit, minmax(10%, 1fr));
                        gap: 2px;
                    }
                }
            }

            .placeholder {
                border-radius: 2px;
                border: 1px solid #ddd;
            }
        }
    }
}
