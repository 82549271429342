#createapp {
    .productselector {
        max-height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 20px;

        .header {
            display: flex;

            h1 {
                color: #121212;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                flex-grow: 1;
            }

            .close {
                svg {
                    margin: 0;
                }
            }
        }

        .parents {
            h2 {
                color: #121212;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                margin: 0;
            }
            .parent-items {
                display: flex;
                gap: 10px;

                .parent {
                    width: 80px;
                    font-size: 10px;
                    transition: opacity 0.25s ease-in-out;

                    p {
                        margin: 0;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    
                    img {
                        max-width: 100%;
                    }

                    &.unsupported {
                        opacity: 0.25;
                    }
                }
            }
        }

        .footer {
            display: flex;
            gap: 10px;

            button {
                color: #fff;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%; /* 19.6px */
                text-transform: uppercase;
                width: 100%;
                background-color: #000;
                display: inline-block;
                padding: 6px;

                svg {
                    color: #fff;
                    margin: 0;
                }

                .working {
                    path {
                        fill: #fff;
                    }
                }
            }

            .submit {
                flex-grow: 1;
            }
            .delete {
                width: 75px;
                flex-grow: 0;
            }
    
        }
        input {
            border: 0;
            border-bottom: 1px solid #B2B2B2;

            color: #272726;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            padding: 16px 0;

            &:focus {
                outline: none;
            }
        }

        .productselector-items {
            min-height: 0;
            margin-right: -20px;
            margin-bottom: 40px;
            margin-top: 40px;
            padding-right: 20px;
            flex-grow: 1;
        }

        .multiselect {
            user-select: none;
            margin-bottom: 10px;
    
            label {
    
                display: flex;
                align-items: center;
    
                span {
                    transition: all .25s ease-in-out;
                    color: rgba(0, 0, 0, 0.6);
                }
    
                .check {
                    margin-right: 10px;
                    border: 1px solid rgba(0, 0, 0, 0.6);
                    border-radius: 4px;
                    width: 25px;
                    height: 25px;
                    display: inline-block;
    
                    svg {
                        display: none;
                        position: relative;
                        top: 4px;
                        left: 4px;
    
                        path {
                            transition: all .25s ease-in-out;
                            stroke: rgba(0, 0, 0, 0.6);
                            fill: rgba(0, 0, 0, 0.6);
                        }
                    }
                }
    
                input {
                    position: absolute;
                    display: none;
    
                    &:checked {
                        &~.check {
                            background-color: #fff;
    
                            svg {
                                display: block;
    
                                path {
                                    stroke: #000;
                                    stroke-width: 2px;
                                }
                            }
                        }
    
                        &~span {
                            color: #000;
                        }
                    }

                    &:disabled {
                        &~span {
                            opacity: 0.2;
                            color: #000;
                            cursor: not-allowed;
                        }
                        &~.check {
                            opacity: 0.2;
                            border: 1px solid #000;
                            background-color: rgba(0, 0, 0, 0.2);
                            cursor: not-allowed;
                        }
                    }
                }
            }
    
            &:hover {
                label {
                    span {
                        color: #000;
                    }
    
                    .check {
                        border: 1px solid #000;
                        background-color: rgba(0, 0, 0, 0.2);
                    }
                }
            }
        }
    }
}
