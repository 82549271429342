#createapp {
    .checkbox {
        user-select: none;

        label {

            display: flex;
            align-items: center;

            span {
                transition: all .25s ease-in-out;
                color: rgba(255, 255, 255, 0.6) !important;
            }

            .check {
                margin-right: 10px;
                border: 1px solid rgba(255, 255, 255, 0.6);
                border-radius: 4px;
                width: 25px;
                height: 25px;
                display: inline-block;

                svg {
                    display: none;
                    position: relative !important;
                    top: 4px;
                    left: 4px;

                    path {
                        transition: all .25s ease-in-out;
                        stroke: rgba(255, 255, 255, 0.6) !important;
                        fill: rgba(255, 255, 255, 0.6) !important;
                    }
                }
            }

            input {
                position: absolute;
                display: none;

                &:checked {
                    &~.check {
                        background-color: #FFF;

                        svg {
                            display: block;

                            path {
                                stroke: #000 !important;
                                stroke-width: 2px;
                            }
                        }
                    }

                    &~span {
                        color: #FFF !important;
                    }
                }
            }
        }

        &:hover {
            label {
                span {
                    color: #FFF !important;
                }

                .check {
                    border: 1px solid #FFF;
                    background-color: rgba(255, 255, 255, 0.6);
                }
            }
        }
    }
}