#createapp {

    .cutouts-page {

        .rendering-blocker {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.9);
            z-index: 100;

            div {
                width: 620px;
                position: absolute;
                right: 20px;
                bottom: 20px;
                text-align: right;

                p {
                    color: #454545;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 137%; /* 21.92px */
                    text-transform: uppercase;
                }

                h1 {
                    color: #000;
                    font-size: 120px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin: 0;
                    margin-bottom: 20px;
                }

                .button {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }

        header {
            border-bottom: 1px solid #e5e5e5;
            padding-left: 200px;
            margin-right: -20px;
            margin-left: -20px;
            justify-content: space-between;

            > div {
                display: flex;
                gap: 30px;
                flex: 0 0 auto;

                &:first-child {
                    justify-content: center;
                    flex: 1;
                }
            }

            button {
                font-size: 15px;
                transition: all 0.25s ease-in-out;
                font-style: italic;

                &.deselected {
                    font-weight: 400;
                    opacity: 0.2;
                    font-style: normal;
                }
            }

            .header-filter {

                div {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }

                input {
                    border: 0;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 137%;
                    /* 21.92px */
                }
            }
        }

        .cutouts-header {
            padding-top: 20px;
    
            h1 {
               // margin: 10px 0;
               font-size: 80px;
               font-style: normal;
               font-weight: 400;
               display: flex;
               line-height: 96.5%;
               align-items: flex-start;     
    
                i {
                    position: relative;
                    bottom: 45px;
                    left: 10px;
                    font-size: 20px;
                }
            }
        }

        .cutouts-content {

            .cutouts-content-header {
                position: relative;
                z-index: 100;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                h1 {
                    font-size: 80px;
                    font-style: normal;
                    font-weight: 400;
                    display: flex;
                    line-height: 96.5%;
                    align-items: flex-start;

                    span {
                        font-family: Domaine Display;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 96.5%;
                    }
                }

                button {
                    padding: 8px 16px;
                    font-size: 12px;
                    font-weight: 700;
                    color: #FFF;
                    border-radius: 16px;
                    background-color: #000;

                    span {
                        margin-right: 8px;
                    }

                    svg {
                        margin: 0;
                    }

                    &.clear {
                        background-color: #FFF;
                        color: #000;
                        border: 1px solid #000;
                    }

                }

                &.fixed {
                    padding: 10px 20px;
                    position: fixed;
                    left: 0;
                    right: 1rem;
                    top: 50px;
                    background-color: #FFF;
                    z-index: 100;

                    .assetfilter, .cutout-content-actions {
                        margin: 0;
                    }
                }
            }

            .cutout-content-actions {
                margin: 20px 0;
                display: flex;
                justify-content: flex-end;
                gap: 10px;
                transition: padding .2s ease-in-out, background-color .2s ease-in-out;
            }

            .cutout-assets-list {
                margin-bottom: 20px;

                ul {
                    li {
                        .asset-image {
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .check {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 25px;
                                height: 25px;
                                border: 1px solid #e5e5e5;
                                transition: background-color .25s ease-in-out;

                                svg {
                                    opacity: 0;
                                    color: #FFF;
                                    height: 25px;
                                    width: 25px;
                                    transition: opacity .25s ease-in-out;
                                }
                            }

                            .warning {
                                position: absolute;
                                bottom: 50%;
                                left: 0;
                                width: 100%;
                                text-align: center;
                                z-index: 1;
                                color: #fff;
                                text-shadow: #000 0 0 16px;
                            }

                            img {
                                width: 100%;
                                height: auto;
                                display: block;
                                opacity: 1;
                                transition: opacity ease-in-out .25s;

                                &:hover {
                                    opacity: 0.8;
                                }
                            }
                        }
                    }
                }

                &.list {
                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;

                        li {
                            display: flex;
                            border-top: 2px solid white !important;
                            border-image: url(../assets/icons/dottedline.svg);
                            border-image-slice: 2 2 2 2 fill;
                            border-image-repeat: repeat;
                            cursor: pointer;
                        //    user-select: none;

                            .asset-header {
                                padding: 0.5rem 0 0.5rem 0;
                                display: flex;
                                flex-direction: column;                             
                                order: 2;

                                .title {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                   // width: 100%;
                                    transition: all 0.25s ease-in-out;
                                  //  overflow: hidden;
                                  //  white-space: nowrap;
                                  //  text-overflow: ellipsis;
                                    color: #20201f;                                    
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 700;

                                    span {
                                        display: block;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        width: 100%;
                                    }
                                }

                                .ean {
                                    color: #20201f;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 300;
                                    line-height: normal;
                                    opacity: .3;
                                }

                            }

                            .asset-image {
                                position: relative;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                order: 1;

                                .check {
                                    margin-right: 10px;

                                    svg {
                                        opacity: 0;
                                        color: #FFF;
                                        height: 20px;
                                        width: 20px;
                                        transition: opacity .25s ease-in-out;
                                    }
                                }

                                img {
                                    position: fixed;
                                    bottom: 20px;
                                    right: 144px;
                                    width: 400px;
                                    height: 400px;
                                    opacity: 0;
                                    transition: opacity 0.5s ease-in-out;
                                }
                            }

                            &:hover {
                                .asset-image {
                                    img {
                                        opacity: 1;
                                    }
                                }
                            }

                            &.selected {
                                .asset-image {

                                    .check {
                                        background-color: #000;

                                        svg {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.grid {

                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                        gap: 12px;
                        flex-wrap: wrap;

                        li {
                            overflow: hidden;
                            color: #7f7f7f;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 140%;
                            cursor: pointer;
                            flex: 0 1 16.6%;
                          //  border-top: 2px solid white !important;
                         //  border-image: url(../assets/icons/dottedline.svg);
                         //   border-image-slice: 2 2 2 2 fill;
                          //  border-image-repeat: repeat;
                          //  user-select: none;

                            .asset-header {
                                padding: 0.5rem 0 1.5rem 0;
                                display: flex;
                                flex-direction: column;

                                .title {
                                    display: flex;
                                    justify-content: space-between;
                                  //  width: 100%;
                                    transition: all 0.25s ease-in-out;
                                  //  overflow: hidden;
                                  //  white-space: nowrap;
                                  //  text-overflow: ellipsis;
                                    color: #20201f;                                    
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 700;

                                    span {
                                        display: block;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        width: 90%;
                                    }
                                }

                                .ean {
                                    color: #20201f;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 300;
                                    line-height: normal;
                                    opacity: .3;
                                }

                                button {
                                    padding: 0;

                                    svg {
                                        margin: 0;
                                        padding: 0;
                                    }
                                }
                            }

                            .asset-image {
                                position: relative;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border: 1px solid #e5e5e5;

                                .check {
                                    position: absolute;
                                    top: -1px;
                                    left: -1px;
                                    width: 30px;
                                    height: 30px;
                                    z-index: 10;
                                }

                            }

                            &.selected {
                                .asset-image {

                                    .check {
                                        background-color: #000;

                                        svg {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }                          
                        }
                    }
                }
            }
        }
    }

    .cutouts-detail-overlay {
        background-color: #191919;

        .cutouts-detail-overlay-header {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #FFF;
            height: 50px;
            line-height: 50px;
        }

        .cutouts-detail-overlay-content {
            padding: 20px;
            height: calc(100% - 50px);
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    .cutouts-asset-info {   
        padding: 20px;    
        background-color: #000;
        height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;

        .cutouts-asset-info-close {
            padding: 10px 0;
            display: flex;
            justify-content: flex-end;
            cursor: pointer;

            span {
                svg {
                    path {
                        stroke: #FFF;
                    }
                }
            }
        }

        .cutouts-asset-info-content {
            > ul {
                > li {                   

                    &.cutouts-asset-info-image {
                        border-top: 0;
                        flex-direction: column;                    

                        span {
                            padding-top: 40px;
                            padding-bottom: 40px;
                            align-self: flex-start;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 96.5%;
                        }
                    }

                    > span {
                        &:first-child {
                            color: rgba(255, 255, 255, 0.6);
                        }
                        
                    }
                }
            }
        }
    }

}