/* Info List */
#createapp {
    .info-list {
        margin-bottom: 20px;

        .info-list-header {
            margin-bottom: 40px;
            padding-top: 10px;
            display: flex;
            justify-content: space-between;
            //  font-family: Domaine Display;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 96.5%;
            border-top: 1px solid rgba(255, 255, 255, 0.26);
            //color: rgba(255, 255, 255, 0.26);

            span {
                color: #fff;
                display: flex;
                gap: 10px;

                &:last-child {

                    a,
                    button.btn {
                        text-decoration: underline;
                        color: #fff;

                        svg {
                            color: #fff;
                            stroke: #FFF;
                            fill: #FFF;
                        }

                        &:disabled{
                            opacity: 0.5;
                        }
                    }
                }
            }
        }

        img {
            width: 100%;
            height: auto;
            display: block;
            opacity: 1;
            transition: opacity ease-in-out 0.25s;

            &:hover {
                opacity: 0.8;
            }
        }

        > ul,
        > div > ul {
            margin: 0;
            padding: 0;
            list-style: none;

            > li {
                padding-top: 10px;
                padding-bottom: 10px;
                display: flex;
                gap: 20px;
                justify-content: space-between;
                align-items: flex-start;
                position: relative;
                overflow: hidden;
                border-top: 2px solid white;
                border-image: url(../../../assets/icons/dottedline_white.svg);
                border-image-slice: 2 2 2 2 fill;
                border-image-repeat: repeat;

                &:last-child {
                    border-bottom: 1px solid white;
                }

                &.is-column {
                    flex-direction: column;
                }

                svg {
                    margin: 0;
                    position: absolute;

                    path {
                        stroke: #fff;
                        opacity: 1;
                    }
                }

                button {
                    display: flex;
                    width: 25px;
                    height: 25px;
                    justify-content: center;
                    align-items: center;

                    svg {
                        margin: 0 !important;
                    }
                }

                span {
                    color: #fff;

                    &:last-child {
                        color: #fff;
                    }
                    &:first-child {
                        flex-shrink: 0;
                    }

                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
