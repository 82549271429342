.moodboard-graph {
    position: relative;
    top: 400px;
    left: 400px;

    .node {
        width: 75px;
        height: 75px;
        border: 1px solid #000;
        background-color: #eee;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        position: absolute;
        transform: translate(-50%, -50%);
        z-index: 10;

        &.material {
            width: 50px;
            height: 50px;
            background-color: #fff;
        }

        &.root {
            width: 90px;
            height: 90px;
            background-color: #ddd;
        }

        span {
            text-align: center;
        }
    }
    .line-label{
        position: absolute;
        transform: translate(-50%, -50%);
        font-size: 9px;
        background-color: rgba($color: #fff, $alpha: 0.8);
        z-index: 5;
    }
    .line {
        position: absolute;
        height: 1px;
        background: #000;
        transform-origin: 0% 0%;
        z-index: 1;
    }
}