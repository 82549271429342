.statistics-usage {
    margin-bottom: 8rem;
    padding: 3rem;
    display: flex;
    gap: 0.5rem;
    width: 50%;
    flex-direction: column;
    background-color: #eee;

    h2 {             
        margin: 0;
        margin-bottom: 0.5rem;
      }

    .statistics-usage-item {
        padding: 1rem 1rem 1rem 2rem;
        display: flex;
        gap: 1rem;
        align-items: center;
        background-color: #FFF;

        > div {
            display: flex;
            flex: auto;
        }

        span {
            flex: 1 0 10%;
            white-space: nowrap;
        }
    }
}