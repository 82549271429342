#createapp {
    .categories {
        display: flex;
        flex-direction: column;
        height: 100%;

        header {
            border-bottom: 1px solid #e5e5e5;

            padding-left: 200px;
            margin-right: -20px;
            margin-left: -20px;
            justify-content: space-between;

            > div {
                display: flex;
                gap: 30px;
            }

            button {
                font-size: 15px;
                transition: all 0.25s ease-in-out;
                font-style: italic;

                &.deselected {
                    font-weight: 400;
                    opacity: 0.2;
                    font-style: normal;
                }
            }

            .header-filter {

                div {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }

                input {
                    border: 0;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 137%; /* 21.92px */
                }
            }
        }

        .categories-content {
            min-height: 0;
            overflow: auto;
            flex-grow: 1;

            h1 {
                font-size: 80px;
                font-style: normal;
                font-weight: 400;
                display: flex;
                line-height: 96.5%;
                align-items: flex-start;

                span {
                    font-family: Domaine Display;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 96.5%;
                }
            }

            .tag {
                border: 1px solid #B0B0B0;
                border-radius: 16px;
                padding: 6px 10px;
                color: #000;
                font-size: 11px;
                font-style: normal;
                font-weight: 700;
            }
        }
    }
}
