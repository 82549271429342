.loader-overlay {

    position: fixed;
    top: 0; 
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(#f0eaf0,0.8);
    z-index: 9999;

    .loader-spinner {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg {
            width: 72px;
            height: 72px;
        }        

    }
}