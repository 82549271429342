#createapp {
    .template-page {
        display: flex;
        flex-direction: column;
        height: 100%;

        header {
            border-bottom: 1px solid #e5e5e5;

            padding-left: 200px;
            margin-right: -20px;
            margin-left: -20px;
            justify-content: space-between;

            div {
                display: flex;
                gap: 30px;
            }

            button {
                font-size: 15px;
                transition: all 0.25s ease-in-out;
                font-style: italic;

                &.deselected {
                    font-weight: 400;
                    opacity: 0.2;
                    font-style: normal;
                }
            }
        }

        .info {
            position: fixed;
            bottom: 15px;
            left: 15px;
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 96.5%; /* 15.44px */
        }

        .template {
            min-height: 0;
            overflow: auto;

            .template-header {
                position: sticky;
                top: 50px;
                z-index: 5;
                pointer-events: none;
                

                .actions {
                    display: flex;
                    gap: 10px;
                    pointer-events: all;
                    float: right;

                    button {
                        padding: 8px 16px;
                        font-size: 12px;
                        font-weight: 700;
                        color: #FFF;
                        border-radius: 16px;
                        background-color: #000;
    
                        &.clear {
                            background-color: #FFF;
                            color: #000;
                            border: 1px solid #000;
                        }
                    }
                }

                h1 {
                    font-size: 80px;
                    font-style: normal;
                    font-weight: 400;
                    margin-bottom: 200px;
                    margin-top: 50px;
                }

                p {
                    width: 340px;
                    height: 100px;
                    margin: 0;

                    span::before {
                        content: "/";
                        margin: 5px;
                    }

                    span:first-child::before {
                        display: none;
                    }
                }
            }

            .template-cameras {
                margin-left: 380px;
                margin-right: 0;
                margin-top: -100px;
            }
        }
    }
}
