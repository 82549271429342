#createapp {
    .project-search {
        header {
            height: auto;
            flex-direction: column;
            position: sticky;
            top: 0;
            background-color: #fff;
            z-index: 10;

            h1 svg{
                cursor: pointer;
                width: 30px;
                height: 30px;
                vertical-align: middle;
            }

            & > div {
                width: 100%;
            }

            .search-field {
                display: flex;
                align-items: center;
                width: 100%;

                svg {
                    width: 58px;
                    height: 25px;
                }

                input {
                    border: 0;
                    height: 88;
                    font-size: 64px;
                    flex-grow: 1;
                    font-family: "Domaine Display";

                    &:focus {
                        outline: none;
                    }
                }
            }

            .assets {
                display: flex;
                flex-direction: row;
                gap: 10px;

                .asset {
                    max-width: 120px;
                    position: relative;

                    .buttons {
                        position: absolute;
                        top: 100px;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: #000;
                        border-radius: 20px;
                        display: flex;
                        flex-direction: row;
                        opacity: 0;
                        transition: opacity ease-in-out 0.25s;

                        button {
                            padding: 10px;
                            opacity: 0.7;
                            transition: opacity ease-in-out 0.25s;
                            background: none;
                            width: auto;
                            margin: 0;

                            svg {
                                color: #fff;
                                margin: 0;
                            }

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }

                    &:hover {
                        .buttons {
                            opacity: 1;
                        }
                    }

                    img {
                        width: 100%;
                    }

                    p {
                        font-size: 14px;
                        margin: 0;
                        text-align: center;
                        font-weight: 700;
                    }
                }
            }

            .header-filter {
                div {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }

                input {
                    border: 0;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 137%;
                }
            }
        }

        .selection .content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            overflow: hidden;
            gap: 20px;

            .submit {
                width: 300px;
                flex-grow: 0;
                flex-shrink: 0;

                button {
                    margin-top: 20px;
                }
            }
        }
    }
}
