#createapp {
    .modelConfigurator {
        max-height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 20px;

        .header {
            display: flex;
            justify-content: space-between;

            h1 {
                color: #121212;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
            }

            .close {
                svg {
                    margin: 0;
                }
            }
        }

        .point-label {
            position: absolute;
            top: 10px;
            left: 10px;
            padding: 2px 8px;
            border-radius: 14px;
            border: 1px solid #C7C7C7;
            color: #C7C7C7;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background-color: #fff;
            z-index: 2;
        }

        .product-image {
            border-radius: 2px;
            border: 1px solid #bcbcbc;
            padding: 20px;
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .current-model {
            margin-bottom: 10px;

            h3 {
                color: #20201F;
                font-family: PP Radio Grotesk;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
            }
        }

        .attributes {
            min-height: 0;
            overflow: auto;
            margin-right: -20px;
            padding-right: 20px;

            .attribute {
                overflow: hidden;
                margin-bottom: 6px;

                div {
                    display: flex;
                    justify-content: space-between;

                    label {
                        color: #20201F;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        opacity: 0.3;
                    }
                }
            }
    
            button {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 10px;
                margin-top: 10px;

                color: #FFF;
                font-family: PP Radio Grotesk;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%; /* 19.6px */
                text-transform: uppercase;
                background-color: #000;

                svg {
                    color: #fff;
                    margin: 0;
                }
            }
    
            .model-items {
                margin-top: 20px;

                h2 {
                    color: #272726;
                    font-family: PP Radio Grotesk;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .item {
                    cursor: pointer;
                    
                    .product-image {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
