.admin-statistics-page-view {
    padding-top: 20px;
    margin-bottom: 20px;

    .admin-statistics-filters-wrapper {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        label {
            display: inline-block;
            margin-bottom: 0.5rem;
        }
    }

    .admin-statistics-page-charts {
        display: flex;
        flex-wrap: wrap;
      //  gap: 2rem;

        .admin-statistics-page-charts-body {
            display: flex;
            flex-direction: column;

            .admin-statistics-page-chart {
                margin-bottom: 1.5rem;
                display: flex;
                flex-direction: column;
                flex: 0 1 auto;
                background-color: #eee;          

                .admin-statistics-page-chart-body {
                    display: flex;
                    // gap: 2rem;

                    .admin-statistics-chart-wrapper {
                        padding: 3rem;
                        min-width: calc(50vw - 10rem);

                        h2 {             
                            margin: 0;
                            margin-bottom: 0.5rem;
                          }

                        .statistics-chart {
                            width: 100% !important;
                            height: auto !important;
                            background-color: #fff;
                        }
                    }
                }

                /* > div {
                   // padding: 60px;
                    max-width: 100% !important;
        
                    canvas {
                      //  min-width: 50% !important;
                        width: 100% !important;
                        height: 100% !important;
                    }
                }*/
            }
        }
    }
}   