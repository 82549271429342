#createapp {
    .batchcutout {

        position: relative;
        width: 100%;
        height: calc(100% - 50px);
        color: #FFF;
        display: flex;
        flex-direction: column;        
        overflow: auto;

        >div {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .header {
            display: flex;
            flex-direction: column;
        }

        h1 {
            margin: 20px 0 10px 0;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
        }

        input,
        select,
        textarea {
            border-radius: 2px;
            border: 1px solid rgba(255, 255, 255, 0.26);
            background: #1a1a1a;
            padding: 16px;
            width: 100%;
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;

            &:focus {
                outline: none;
            }

            .placeholder {
                color: #555;
            }
        }

        table {
            border: 1px solid rgba(255, 255, 255, 0.26);

            thead th {
                border-bottom: 1px solid rgba(255, 255, 255, 0.26);
            }

            tr {
                border-collapse: collapse;
                border-bottom: 1px dashed rgba(255, 255, 255, 0.26);

                /* &:last-child {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.26);
                }*/
            }

            thead th,
            tbody td {
                padding: 0.25rem;
            }

            thead,
            tbody tr {
                display: table;
                width: 100%;
                table-layout: fixed;
            }

            tbody {
                display: block;
                overflow-y: auto;
                height: 600px;
            }
        }

        button,
        .button {
            color: #1a1a1a;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            width: 100%;
            max-width: 250px;
            background-color: #fff;
            justify-content: center;        
            padding: 6px;
            margin-top: 40px;
            transition: all .2s ease-in-out;
        }

        .batchcutout-projectname,
        .batchcutout-reference {
            flex: 0 1 49%;

            input {              
                width: 100%;
            }
        }

        .batchcutout-list-wrapper {
            display: flex;
            gap: 1rem;

            .batchcutout-list {
                display: flex;
                flex-direction: column;
                flex: 1;
            }
        }

        .batchcutout-actions {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
        }

    }

    .batchcutout-step {
        padding: 60px 180px;
        display: flex;
        flex-direction: column;

        .batchcutout-create {
            display: flex;
            gap: 2%;
            flex-wrap: wrap;

            input:disabled {
                background: #333;
            }

            .batchcutout-folder {
                width: 100%;
            }

            .batchcutout-list-wrapper {
                flex: 1;
            }
        }

        .angle-selector {
            overflow-y: auto;
            height: calc(100vh - 240px);
        }
    }
}