@font-face {
    font-family: "Domaine Display";
    font-display: swap;
    font-style: normal;
    src: local("Domaine Display"), url("./assets/fonts/Domaine Display Regular.woff2") format("woff2");
}

@font-face {
    font-family: "PP Radio Grotesk";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: local("PP Radio Grotesk"), url("./assets/fonts/PPRadioGrotesk-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "PP Radio Grotesk";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: local("PP Radio Grotesk bold"), url("./assets/fonts/PPRadioGrotesk-Black.woff2") format("woff2");
}

#createapp {
    * {
        box-sizing: border-box;
        scrollbar-width: thin;
    }

    &.reduced-motion {
        * {
            animation: none !important;
            transition-duration: 0s !important;
        }
    }

    min-height: 100vh;
    background-color: #fff;
    color: #000;
    font-family: "PP Radio Grotesk";

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: "Domaine Display";
    }

    a {
        text-decoration: none;
        color: unset;
    }

    .animated {
        transition: all 0.15s ease-in-out;
        padding-right: 6px;

        > svg {           
            margin-right: 12px;
            transition: all 0.15s ease-in-out;

            path {
                transition: all 0.15s ease-in-out;
                fill: #242424;
            }
        }

        &:hover {
            padding-right: 0;
            
            > svg {
                margin-right: 18px;
                transform: rotate(90deg);

                path {
                    opacity: 0.1;
                }
            }
        }
    }

    .container {
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        position: absolute;
        overflow: hidden;

        .page-container {
            background-color: #fff;
            position: absolute;
            left: 0;
            right: 0;
            width: 100vw;
            height: 100vh;
            overflow: visible;

            .loader {
                position: absolute;
                left: 0;
                right: 0;
                width: 100vw;
                height: 100vh;
                background: #f0eaf0;

                svg {
                    position: absolute;
                    left: 22px;
                    bottom: 29px;
                }

                &.before {
                    top: -100%;
                }
                &.after {
                    top: 100%;
                }
            }
            
            .page {
                background-color: #fff;
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
            }
        }
    }
}

#createapp, .createapp {
    button,
    .button {
        border: 0;
        background: none;
        cursor: pointer;
        display: flex;
        color: #242424;
        align-items: center;

        svg {
            color: #242424;
            margin-right: 8px;
        }        

        &:disabled {
            opacity: 0.2;
            cursor: unset;
        }

        &.primary {
            color: #fff;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 19.6px */
            text-transform: uppercase;
            background-color: #000;
            display: inline-block;
            padding: 6px 12px;
        }

        &.secondary {
            color: #000;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 19.6px */
            text-transform: uppercase;
            background-color: #fff;
            display: inline-block;
            padding: 6px 12px;
            border: 1px solid #000;
        }

        &.rounded {
            background-color: #fff;
            color: #000;
            padding: 12px;
            border-radius: 14px;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%; /* 13.2px */

            svg {
                color: #000;
            }
        }
    }
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input::-ms-clear {
    display: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: calc(100% - 1px);
    width: 270px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 5px 10px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}
