.orderlineList {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;

        > li {
            padding: 0;
            border: none;
            flex-direction: column;
            align-items: normal;
            display: block !important;

            div {
                span {
                    color: #fff;
                }
            }

            .project-images-list-img {
                margin-bottom: 10px;
                position: relative;

                img,
                video {
                    width: 100%;
                }

                &.no-transition {
                    img {
                        transition: none !important;
                    }
                }
            }

            .project-images-list-name {
                display: flex;
                justify-content: space-between;
                color: #fff;
            }

            .project-images-list-actions {
                display: flex;
                justify-content: flex-start;
                color: #fff;
                gap: 10px;
                padding: 5px 0;

                .rounded {
                    width: auto !important;
                }

                button {
                    height: 20px;
                    width: 20px;

                    svg {
                        color: #fff;
                        height: 20px;
                        width: 20px;
                        transition: opacity 0.25s ease-in-out;
                    }
                }
            }

            .project-images-list-date {
                span {
                    color: rgba(255, 255, 255, 0.6) !important;
                }
            }
        }
    
}
