#createapp {
.animationEditor {
    display:flex;
    flex-direction: column;
    text-align: center;
    padding: 75px 0;

    .player {
        flex-basis: 200px;
        flex-grow: 1;
        flex-shrink: 1;
        min-height: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .player-container {
            height: 100%;
            aspect-ratio: 1 / 1;
        }
    }

    .save {
        display: flex;
        justify-content: center;
        margin-top: 50px;

        button {
            padding: 10px 75px;
        }
    }

    .editor {
        .shots {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;

            .grabbable {
                flex-grow: 0;
                flex-shrink: 0;
            }
            
            .thumbnail, .addnew {
                border-radius: 2px;
                position: relative;
                cursor: pointer;
                width: 170px;
                height: 170px;
                justify-content: center;
                align-items: center;
                display: flex;
                border: 1px solid #c7c7c7;

                video {
                    width: 100%;
                }

                svg {
                    color: #fff;
                    margin: 0;
                }

                .progress {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 6px;
                    background-color: rgba(255, 255, 255, 0.3);

                    .bar {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        height: 6px;
                        background-color: rgba(255, 255, 255, 0.8);
                    }
                }

                .buttons {
                    position: absolute;
                    bottom: 10px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: #000;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: row;
                    opacity: 0;
                    transition: opacity ease-in-out 0.25s;

                    button {
                        padding: 10px;
                        opacity: 0.7;
                        transition: opacity ease-in-out 0.25s;

                        svg {
                            color: #fff;
                            margin: 0;
                        }

                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                &:hover {
                    .buttons {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
}