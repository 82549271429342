#createapp {
    .quick-assetcart {
        position: absolute;
        top: 50px;
        right: 20px;
        color: #000;
        z-index: 11;

        .placeholder{
            display: flex;
            position: relative;
            align-items: center;
            cursor: pointer;

            .badge {
                display: block;
                position: absolute;
                bottom: -2px;
                left: -5px;
                background-color: #000;
                color: #fff;
                border-radius: 100%;
                width: 16px;
                height: 16px;
                text-align: center;
                line-height: 16px;
                font-size: 10px;
            }
        }
    }

    .assetcart {
        .assetcart-header {
            display: flex;
            align-items: flex-start;
            gap: 10px;
        }

        h1 {
            font-size: 60px;
            font-style: normal;
            font-weight: 400;
            display: flex;
            line-height: 96.5%;
            align-items: flex-start;
            margin: 20px 0;
            flex-grow: 1;

            span {
                font-family: Domaine Display;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 96.5%;
                margin-left: 10px;
            }
        }

        h2 {
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            display: flex;
            line-height: 96.5%;
            align-items: flex-start;
            margin: 50px 0 20px 0;
            border-bottom: 1px solid #fff;

            span {
                font-family: Domaine Display;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 96.5%;
                margin-left: 10px;
            }

            input {
                font-size: 40px;
                font-style: normal;
                font-weight: 400;
                background: transparent;
                border: 2px solid #404040;
                color: #fff;
                width: 400px;
            }

            button {
                margin-left: 10px;
                margin-top: 10px;

                svg {
                    color: #fff;
                }
            }
        }
    }

    .createcart {
        .content {
            width: 350px;
            align-self: flex-end;
        }
    }
}
