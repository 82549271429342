

.page-container{
    //max-width: 1512px;
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;

    header {
        padding: 15px 20px;
        display: flex;
        height: 50px;

        .logo {
            font-size: 16px;
            font-weight: 700;
            margin: 0;
            width: 40px;
            margin-right: 140px;
            display: inline-block;
        }

        button {
            font-size: 16px;
            font-weight: 700;
            border: 0;
            background: none;
            padding: 0;
            cursor: pointer;
        }

        .spacer {
            flex-grow: 1;
        }

        span {
            font-size: 16px;
            font-weight: 700;
        }
    }

    .page-content {
        flex-grow: 1;
        padding: 0 20px;
        min-height: 0;
        overflow: auto;
    }
}