#createapp {
    .submit {
        .details {
            flex-grow: 1;
            flex-shrink: 1;
            overflow: auto;

            .image {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .image-holder {
                    height: 65px;
                    width: 65px;
                    margin-right: 10px;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    position: relative;

                    svg {
                        position: absolute;
                        width: 16px;
                        height: 16px;
                        top: 0;
                        left: 0;
                        color: #ccc;
                    }
                }

                img, video {
                    transition: all ease-in-out 0.25s;
                    object-fit: cover;
                }

                p {
                    flex-grow: 1;
                    transition: all ease-in-out 0.25s;
                }

                button svg{
                    color: #fff;
                }

                &.deleting {
                    img, p{
                        opacity: 0.5;
                    }
                }
            }

            .error {
                color: #f00;
            }
        }

        .plan {
            display: flex;
            justify-content: stretch;
            gap: 10px;
            margin-bottom: 20px;
            flex-direction: column;

            label {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
                display: block;
            }

            select {
                border-radius: 2px;
                border: 1px solid rgba(255, 255, 255, 0.26);
                background: #1A1A1A;
                padding: 10px 16px;
                width: 100%;
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;

                &:focus {
                    outline: none;
                }
            }
        }

        .render, .preview {
            color: #000;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 19.6px */
            text-transform: uppercase;
            background-color: #fff;
            display: inline-block;
            padding: 9px;
        }

        .preview {
            color: #fff;
            background-color: #000;
            border: 1px solid #fff;
        }
    }
}