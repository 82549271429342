.project-detail {  
    
    .loading {
        display: flex;
        justify-content: center;

        svg {
            stroke: #FFF;
            fill: #FFF;

            path:first-child {
                opacity: .25 !important;
            }
        }
    }

    .project-detail-header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        h1 {
            margin-bottom: 30px;
            font-size: 80px;
            font-style: normal;
            font-weight: 400;
            line-height: 96.5%;
        }

        > div {
            display: flex;
            gap: 12px;
        }
    }

    .project-content-wrapper {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 24px;

        .project-info {
            padding-right: 20px;
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 24px;
        }
    }

}

.info-list {

    .info-list-header {
        button {
            transition: all ease-in-out .25s;
        }
    }

    button {
        padding: 0;
        margin: 0;
    }

    > ul {
        > li {
            span {
                color: rgba(255, 255, 255, 0.6);

                &:last-child {
                    color: #FFF;
                }
            }
        }
    }

    &.project-images-list {

        > ul {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 24px;

            > li {
                padding: 0;
                border: none;
                flex-direction: column;
                align-items: normal;
                display: block !important;

                div {
                    span {
                        color: #FFF;
                    }
                }

                .project-images-list-img {
                    margin-bottom: 10px;
                    position: relative;

                    img, video {
                        width: 100%;
                    }

                    &.no-transition {
                        img {
                            transition: none !important;
                        }
                    }
                }

                .project-images-list-name {
                    display: flex;
                    justify-content: space-between;  
                    color: #FFF;
                }

                .project-images-list-actions {
                    display: flex;
                    justify-content: flex-start;  
                    color: #FFF;
                    gap: 10px;
                    padding: 5px 0;
                    
                    .rounded {
                        width: auto !important;
                    }

                    button {
                        height: 20px;
                        width: 20px;

                        svg {
                            color: #FFF;
                            height: 20px;
                            width: 20px;
                            transition: opacity .25s ease-in-out;
                        }
                    }
                }

                .project-images-list-date {
                    span {
                        color: rgba(255, 255, 255, 0.6) !important;
                    }
                }

            }
        }

        .project-images-list-no-images {
            display: flex;
            justify-content: center;
        }

    }
}

#createapp {
    .info-list {
        button {
            position: relative;

            .project-edit-overlay {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.6);
                transition: opacity .25s ease-in;
                opacity: 0;

                span {
                    display: flex;
                    height: 50px;
                    width: 50px;
                    justify-content: center;
                    align-items: center;
                    background-color: #000;
                    border-radius: 50px;

                    svg {
                        margin-right: 0;
                        filter: invert(1);
                    }
                }
            }

            &:hover {
                .project-edit-overlay {
                    opacity: 1;
                }
            }
        }
    }
}