

#createapp {
    .overlay {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .overlay-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 50px;
            background: rgba($color: #fff, $alpha: 0.4);
            flex-shrink: 0;
            flex-grow: 0;

            button {
                border-radius: 100px;
                border: 1px solid #FFF;
                background: #1A1A1A;
                padding: 6px 16px;

                svg {
                    color: #fff;
                    margin: 0;
                }
            }
        }

        .overlay-content {
            background: #1a1a1a;
            color: #fff;
            padding: 20px;
            flex-grow: 1;
            overflow: auto;
        }
    }
}