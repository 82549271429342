#createapp {
.designer-container {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 100;


    .designer-overlay {
        position: absolute;
        background: #1A1A1A;
        top: -20px;
        left: -20px;
        right: -20px;
        bottom: -20px;
    }

    &.open {
        .designer-viewport{
            padding: 100px 50px 100px 50px;
            transition: padding 0.25s ease-in-out;
            
            .pagination {
                display: block;
            }
    
            .navigation {
                display: flex;
            }
        }
    }

    .angle-selector {
        position: absolute;
        top: 50%;
        left: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        transform: translateY(-50%);
        max-height: 100%;
        justify-content: center;
        align-items: flex-start;

        .angle {
            cursor: pointer;
            height: 50px;
            flex: 1 1 auto;
            min-height: 0;

            img, video {
                height: 100%;
                display: block;
                transition: all ease-in-out 0.25s;
                object-fit: cover;
                filter: brightness(0.5);
            }

            &.selected {
                img, video {
                    filter: none;
                }
            }
        }
    }

    .designer-viewport {
        position: relative;
        top: 0;
        left: 75px;
        width: calc(100% - 75px);
        height: 100%;
        padding: 0;

        .pagination {
            position: absolute;
            left: 40px;
            bottom: -40px;
            display: none;

            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;

            .count {
                opacity: 0.2;
                margin-left: 5px;
            }
        }

        .navigation {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -40px;
            z-index: 101;
            display: none;
            gap: 15px;
        }

        .canvas-container {
            position: relative;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transition: top 0.25s ease-in-out, left 0.25s ease-in-out ;

            .canvas {
                position: absolute;
                transition: all 0.25s ease-in-out;
                top: 0;
                left: 0;
                width: 500px;
                height: 500px;
                display: flex;
                align-items: center;
                justify-content: center;

                .camera-view {
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                    aspect-ratio: 1/1;
                }

                .hotspots {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;

                    .hotspot {
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        background-color: #000;
                        transform: translate(-50%, -50%);
                        color: #fff;
                        cursor: pointer;
                        text-align: center;
                        font-size: 13px;
                        line-height: 20px;

                        &.changed {
                            background-color: #B4CDC2;
                            color: #000;
                        }

                        &.hover {
                            background-color: #fff;
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}
}