.login-page {
    height: 100vh;
    background: #f0eaf0;
    font-family: "PP Radio Grotesk";

    * {
        box-sizing: border-box;
    }

    .logo {
        position: absolute;
        left: 22px;
        bottom: 29px;
        z-index: 1;
        opacity: 0.25;
    }

    .page-content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 350px;
        z-index: 10;
    }

    h1 {
        font-family: "Domaine Display";
        color: #121212;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
        margin-bottom: 25px;
    }

    h2.divider {
        text-align: center;
        font-family: "Domaine Display";
        color: #121212;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;

        &::before,
        &::after {
            flex: 1;
            content: "";
            height: 1px;
            background-color: #121212;
            margin: 10px;
        }
    }

    input {
        width: 100%;
        height: 50px;
        margin-bottom: 8px;
        border-radius: 2px;
        background: #f0eaf0;
        border: 1px solid rgba(0, 0, 0, 0.13);
        padding: 0 10px;
        font-weight: bold;
        color: #000;
        font-family: PP Radio Grotesk;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;

        &::placeholder {
            font-weight: bold;
            color: #000;
            font-family: PP Radio Grotesk;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            opacity: 1;
        }
    }

    button,
    .button {
        width: 100%;
        padding: 10px 0;
        font-weight: bold;
        color: #000;
        font-family: PP Radio Grotesk;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        border: none !important;
        border-radius: 0 !important;
        color: #fff;
        background-color: #000 !important;
        cursor: pointer;
        text-transform: uppercase;
        display: inline-block;
        text-align: center;
        line-height: normal;

        svg {
            height: 14px;
            vertical-align: baseline;
            margin-right: 10px;
        }
    }

    .error {
        margin-top: 0;
        color: #f00;
    }

    a {
        color: #000;
        font-family: PP Radio Grotesk;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 300%;
        text-decoration: none;
    }
}
