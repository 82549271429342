.toggle-button {  
    display: flex;
    align-items: center;
    position: relative;

    i {
        position: absolute;
        width: 1rem;
        height: 1rem;
        
    }

    span {       
        line-height: normal;
    }

    &.has-icon {

        i {
            border: 1px solid #000;
        }

        span {
            padding-left: 1.5rem;
        }

        &.active {
            i {         
                &:before {
                    content: "";
                    position: absolute;
                    top: 2px;
                    left: 2px;
                    width: calc(100% - 4px);
                    height: calc(100% - 4px);
                    background-color: #000;
                }
            }
        }

    }
}