#createapp {
    .bottomdrawer {
        background: #1a1a1a;
        color: #fff;
        display: flex;
        gap: 15px;
        flex-direction: column;
        padding: 20px;
        justify-content: stretch;

        .logo {
            position: absolute;
            bottom: 20px;
            left: 30px;
            z-index: -1;
            height: 254px;
            max-height: 80%;
        }

        .header {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            button {
                svg {
                    color: #fff;
                    margin: 0;
                }
            }
        }

        .content {
            
            h1 {
                color: #fff;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                margin: 20px 0 10px 0;

                .faded {
                    opacity: 0.5;
                }
            }

            h2 {
                color: #fff;
                font-size: 16px;
                margin: 10px 0;
                font-weight: 400;
                text-transform: uppercase;
            }

            input,
            select {
                border-radius: 2px;
                border: 1px solid rgba(255, 255, 255, 0.26);
                background: #1a1a1a;
                padding: 16px;
                width: 100%;
                color: #fff;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;

                &:focus {
                    outline: none;
                }

                .placeholder {
                    color: #555;
                }
            }

            button {
                color: #1a1a1a;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                width: 100%;
                background-color: #fff;
                display: inline-block;
                padding: 6px;
                margin-top: 40px;
            }

            .wizard {
                display: flex;
                flex-direction: row;
                padding: 50px;
                gap: 20px;

                .wizard-step {
                    flex-basis: 100px;
                    flex-grow: 1;
                    flex-shrink: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;

                    h1 {
                        align-self: center;
                        text-align: center;
                        border: 1px solid rgba(255, 255, 255, 0.26);
                        color: #fff;
                        font-size: 14px;
                        display: inline-block;
                        height: 28px;
                        width: 28px;
                        border-radius: 100%;
                        line-height: 26px;
                        margin: 0;
                    }

                    button {
                        margin-top: 16px;
                    }
                }
            }
        }
    }
}
