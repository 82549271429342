.turntable {

    cursor: grab;

    .loading {

        position: absolute;
        z-index: 99;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        span {

            &:first-child {
                margin-right: 5px;
                display: block;
                height: 25px;
                width: 25px;
            }

            svg {
                stroke: #FFF;
                fill: #FFF;

                path:first-child {
                    opacity: .25 !important;
                }
            }
        }
    }

    .degress {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .spin360 {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 15%;

        svg {
            g {
                path {
                    fill: #FFF;
                }
            }
        }
    }

    >div {
        padding-top: 100%;
        position: relative;
    }
}