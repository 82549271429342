.menu {
    padding: 0 !important;
    background-color: #F0EAF0 !important;
    height: 800px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 100px) !important;

    header {
        padding: 15px 20px;
        display: flex;
        height: 50px;

        .logo {
            font-size: 16px;
            font-weight: 700;
            margin: 0;
            width: 40px;
            margin-right: 140px;
            display: inline-block;
        }
    }

    .menu-body {
        flex: 1;
        overflow: auto;

        ul {
            margin: 0;
            padding-top: 20px;
            padding-left: 200px;
            list-style: none;
            display: flex;
            flex-direction: column;

            li {
                font-family: "Domaine Display";
                font-size: 80px;
                
                svg {
                    vertical-align: super;
                }
            }
        }
    }

    footer {
        padding: 15px 20px;
        display: flex;
        height: 50px;
        font-size: 11px;
        font-weight: 700;
        color: #3d3d3d;

        .menu-brand {            
            width: 180px;   
        }     
        
        .menu-user {
            flex: 1;
            span {
                &:first-child{
                    margin-right: 10px;
                    color: rgba(#3d3d3d,0.3);
                }
            }
        }

        .menu-actions {
            button {
            font-size: 11px;
            font-weight: 700;
            }
        }
    }
}