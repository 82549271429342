.EZDrawer {
    .EZDrawer__checkbox {
        display: none;

        &:checked {
            & ~ .EZDrawer__overlay {
                display: block;
                opacity: 1;
            }

            & ~ .EZDrawer__container {
                visibility: visible;
                transform: translate3d(0, 0, 0) !important;
            }
        }
    }

    .EZDrawer__overlay {
        display: none;
        height: 100vh;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
    }

    .EZDrawer__container {
        position: fixed;
        visibility: hidden;
        transition: all;
    }
}

#createapp {
    .sidedrawer {
        background: #1a1a1a;
        color: #fff;
        display: flex;
        gap: 15px;
        flex-direction: column;
        padding: 20px;
        padding-right: 40px;
        justify-content: stretch;

        .header {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            button {
                svg {
                    color: #fff;
                    margin: 0;
                }
            }

            h1 {
                width: 100%;
                color: #fff;
                font-family: PP Radio Grotesk;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 135%; /* 14.85px */
                opacity: 0.5;
                border-top: 1px solid #fff;
            }
        }
    }
}
