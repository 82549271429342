body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

.diagram {
  width:100%;
  height: 100%;
}

.temp-placeholder {
  background-color: #e7d8f6;
  font-family: 'Comic Sans MS';
}

.dev-banner {
  position: fixed;
  background-color: #e7d8f6;
  z-index: 99999;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 10px;
  text-align: center;
  font-size: 10px;
}

.config-selector {
  position: fixed;
  background-color: #e7d8f6;
  z-index: 99999;
  top: 0;
  left: calc(75%);
  transform: translateX(-50%);
  padding: 4px 10px;
  text-align: center;
  font-size: 10px;
}
