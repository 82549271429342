#root {
    .dialog-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
    
        .dialog {
            width: 580px;
            height: 350px;
            background-color: #fff;
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            color: #7f7f7f;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
    
            h1 {
                margin: 0;
                color: #000;
                font-size: 40px;
                font-style: normal;
                font-weight: 400;
                line-height: 96.5%; /* 38.6px */
            }
    
            .confirm-body, .message-body {
                flex-grow: 1;
                flex-shrink: 1;
                min-height: 0;
                display: flex;
                flex-direction: column;
                
                textarea {
                    width: 100%;
                    height: 80px;
                    margin-bottom: 30px;
                }

                .details {
                    flex-grow: 1;
                    flex-shrink: 1;
                    overflow: auto;
                    width: 100%;
                    font-size: 12px;
                    line-height: 100%;
                }
            }


            .confirm-footer, .message-footer {
                display: flex;
                justify-content: stretch;
                gap: 20px;

                button {
                    flex-grow: 1;
                    justify-content: center;
                    text-transform: uppercase;
                    color: #242424;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%; /* 19.6px */
                    border: 1px solid #242424;
                    padding: 8px;

                    &.confirm {
                        background-color: #242424;
                        color: #fff;
                    }
                }
            }
        }
    }
}
