#createapp {
.projectlist {
    display: grid;
    gap: 21px;
    overflow: auto;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-auto-rows: max-content;
    position: relative;
    min-height: 50px;

    .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 48px;
        height: 48px;
        transform: translate(-50%, -50%);
    }

    .project {
        cursor: pointer;
        overflow: hidden;
        font-size: 16px;
        font-style: normal;
        border-top: 2px solid white;
        border-image: url(../../assets/icons/dottedline_white.svg);
        border-image-slice: 2 2 2 2 fill;
        border-image-repeat: repeat;
        padding-top: 10px;
        padding-bottom: 10px;

        .project-image {
            padding: 0px;
            margin-bottom: 10px;
            position: relative;
            width: 100%;
            aspect-ratio: 1 / 1;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
                display: block;
                transition: opacity ease-in-out 0.25s;
                background-color: currentColor;
            }

            img:hover{
                opacity: 1;
            }

            .lazy-load-image-background {
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
            }

            &.images-2, &.images-3 {
                img {
                    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.3);
                }
            }

            &.images-2 {
                img:nth-child(1) {
                    top: 10%;
                    left: 20%;
                    width: 70%;
                    height: 70%;
                }
                img:nth-child(2) {
                    top: 20%;
                    left: 10%;
                    width: 70%;
                    height: 70%;
                }
            }

            &.images-3 {
                img:nth-child(1) {
                    top: 10%;
                    left: 30%;
                    width: 60%;
                    height: 60%;
                }
                img:nth-child(2) {
                    top: 20%;
                    left: 20%;
                    width: 60%;
                    height: 60%;
                }
                img:nth-child(3) {
                    top: 30%;
                    left: 10%;
                    width: 60%;
                    height: 60%;
                }
            }
        }

        .project-footer {

            .project-name {
                display: flex;
                justify-content: space-between;
            }

            .files, .date {
                opacity: 0.7;
            }
        }
    }
}
}
