.filter {  
    margin: 20px 0;
    
    > .filter-properties {
        display: flex;
        gap: 12px;

        > .filter-input {
            width: 200px;

            &.date-input {
                display: flex;
                width: 300px;
            }

            .react-datepicker-wrapper {

                input {
                    margin-right: 0;
                    border: 1px solid rgba(0, 0, 0, 0.26);
                    background: #FFF;
                    padding: 4px 8px;
                    width: 100%;
                    min-height: 32px;
                    color: hsl(0, 0%, 50%);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    margin-bottom: 10px;
                    font-size: 12px;
                    box-shadow: none !important;
                    outline: none;


                    &:disabled {
                        color: rgba(0, 0, 0, 0.26);
                        background: rgba(239, 239, 239, 0.3);
                    }

                    &:focus {
                        outline: none;
                    }
                }

                button {
                    padding: 0;
                    height: auto;
                    top: 6px;
                    opacity: 0.2;
                    transition: opacity .1s ease-in;

                    &:hover {
                        opacity: 0.4;
                    }

                    &:after {
                        content: "";
                        border-radius: 0;
                        background-color: transparent;
                        position: relative;
                        appearance: none;
                        -webkit-appearance: none;
                        height: 20px;
                        width: 20px;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false' class='css-tj5bde-Svg'%3E%3Cpath d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'%3E%3C/path%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-size: auto;
                        cursor: pointer;
                    }
                }
            }

            label {
                display: block;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;                
                margin-bottom: 10px;
            }

            input {
                flex-grow: 0;
                margin-right: 10px;
            }

            .text-input {
                display: flex;
                flex-direction: column;                

                input {
                    margin-right: 0;
                    padding: 8px 26px 8px 16px;                
                    flex: 1;
                    font-size: 12px;
                    border: 1px solid hsl(0, 0%, 80%);
                   

                    &:focus {
                        outline: none;
                    }

                    &::-webkit-search-cancel-button {
                        position: relative;
                        -webkit-appearance: none;
                        height: 20px;
                        width: 20px;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false' class='css-tj5bde-Svg'%3E%3Cpath d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'%3E%3C/path%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-size: auto;
                        cursor: pointer;
                    }
                }

                &.search {
                    position: relative;

                    input {                       
                       // padding: 8px 30px 8px 16px;
                        border-radius: 20px;
                        color: #FFF;
                        background-color: rgba(0,0,0,0.5);    
                        
                        &::placeholder {
                            color: #FFF;
                        }

                        &::-webkit-search-cancel-button {
                            filter: invert(1);
                        }
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        left: 87%;
                        top: 50%;
                        height: 13px;
                        width: 13px;
                        transform: translate(0,-50%);
                        background-image: url(../../../assets/icons/search.svg);
                        background-repeat: no-repeat;                    
                        //opacity: 0.6;
                        filter: invert(1);
                    }

                }
            }
        }
    }

    > .filter-values {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        padding: 20px 0;

        .clear {
            border-radius: 20px;
            background: #000;
            padding: 5px 15px;

            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            cursor: pointer;
        }

        > .filter-value {
            border-radius: 20px;
            background: #F0EAF0;
            width: auto;
            padding: 5px 15px;

            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%;
            /* 13.2px */

            svg {
                vertical-align: text-bottom;
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }
}

.fixed > .filter {
    margin: 0;
}