.resetpassword {

    > div {

        display: flex;
        flex-direction: column;

        input {
            box-sizing: border-box;
            margin-bottom: 10px;
            padding: 4px 11px;
            border-radius: 6px;
            line-height: 1.5714285714285714;
            border: 1px solid #d9d9d9;
            transition: all .2s;
            outline: 0;

            &:focus {
                border-color: #1677ff;
                box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
            }
        }

        button {
            padding: 4px 15px;
            line-height: 1.5714285714285714;
            border-radius: 6px;
            color: #FFF;
            background-color: #1677ff;
            border: 1px solid transparent;
            transition: all .2s;
            cursor: pointer;
            outline: 0;

            &:hover {
                background-color: #4096ff;                
            }
            
        }

    }

}