.node-editor {
    display: flex;
    flex-direction: row;
    height: 100%;

    .sidebar {
        width: 340px;

        .ant-tabs {
            height: 100%;

            .ant-tabs-content-holder {
                overflow-y: scroll;
                padding-right: 20px;
                scrollbar-width: thin;

                .ant-list-item {
                    cursor: grab;

                    &.disabled {
                        opacity: 0.3;
                        cursor: not-allowed;
                    }
                }
            }
        }
    }

    .diagram-container {
        flex-grow: 1;
        background-color: #fafafa;
        border: 1px solid #ddd;
    }

    .node-item {
        border: 1px solid #aaa;
        border-radius: 4px;
        width: 140px;
        background-color: #eee;

        .replace-info {
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.75);
            z-index: 1000;
            text-align: center;
            padding-top: 10px;
            font-size: 20px;
            font-weight: 700;
            display: none;
        }

        &.replace {
            &::after {
                content: "Drop to replace";
                top: 0;
                left: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.75);
                z-index: 1000;
                text-align: center;
                padding-top: 10px;
                font-size: 20px;
                font-weight: 700;
            }
        }

        &.selected {
            border-color: #1ec8ff;
            outline: 4px solid #e5f9ff;
        }

        &.node-item-scene {
            width: 220px;
        }

        &.node-item-model {
            .header {
                background-color: #b7eb8f;
            }
        }
        &.node-item-modelpack {
            .header {
                background-color: #eaff8f;
            }
        }
        &.node-item-material {
            .header {
                background-color: #91caff;
            }
        }
        &.node-item-modifier {
            .header {
                background-color: #d3adf7;
            }
        }

        .header {
            margin-bottom: 10px;
            font-weight: bold;
            padding: 10px;
        }

        .props {
            margin-bottom: 10px;
            padding: 10px;
        }

        .ports {
            min-height: 25px;
        }

        .port-label {
            position: relative;
            margin-bottom: 6px;
            display: flex;
            cursor: pointer;

            &.selected {
                font-weight: bold;
            }

            .port {
                position: absolute;
                width: 15px;
                height: 15px;
                border: 1px solid #aaa;
                top: 2px;
            }

            span {
                margin-right: 18px;
                text-align: right;
                flex-grow: 1;
            }

            &.out {
                .port {
                    left: -1px;
                    top: -6px;
                    border-top-right-radius: 100%;
                    border-bottom-right-radius: 100%;
                }
            }

            &.in {
                .port {
                    right: -1px;
                    top: 3px;
                    border-top-left-radius: 100%;
                    border-bottom-left-radius: 100%;
                }
            }

            &.port-model {
                .port {
                    background-color: #b7eb8f;
                }
            }
            &.port-modelpack {
                .port {
                    background-color: #eaff8f;
                }
            }
            &.port-material {
                .port {
                    background-color: #91caff;
                }
            }
            &.port-modifier {
                .port {
                    background-color: #d3adf7;
                }
            }
        }
    }
}
