#createapp {
    .asset-details {
        .asset-detail-header {
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            h1 {
                margin-bottom: 60px;
                margin-top: 0;
                font-size: 80px;
                font-style: normal;
                font-weight: 400;
                line-height: 96.5%;
            }
        }

        .asset-details-overlay-content {
            display: flex;
            gap: 20px;

            & > div {
                flex-basis: 100px;
                flex-shrink: 0;
                flex-grow: 1;
            }

            .meta {
                min-width: 0;
                line-height: 150%;

                .button {
                    margin-top: 20px;
                }
            }

            .products {
                flex-basis: 100px;
                flex-grow: 1;
                flex-shrink: 1;
                min-width: 0;
                position: relative;
                margin-top: 30px;

                .loading {
                    position: absolute;
                    top: 100px;
                    left: 50%;
                    z-index: 1;
                    width: 48px;
                    height: 48px;
                    transform: translate(-50%, -50%);

                    path {
                        fill: #fff;
                    }
                }

                .product {
                    border-top: 2px solid white;
                    border-image: url(../../assets/icons/dottedline_white.svg);
                    border-image-slice: 2 2 2 2 fill;
                    border-image-repeat: repeat;
                    padding: 10px;
                    display: flex;

                    .name {
                        flex-grow: 1;

                        h3 {
                            margin: 0;
                            color: #ebe6e4;
                            font-family: PP Radio Grotesk;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 110%; /* 15.4px */
                        }

                        span {
                            color: #767676;
                            font-size: 10px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 135%;
                        }
                    }

                    .point {
                        width: 26px;
                        height: 26px;
                        border-radius: 100%;
                        background-color: #fff;
                        color: #000;
                        text-align: center;
                        font-size: 13px;
                        line-height: 26px;
                        font-weight: 700;
                        flex-grow: 0;
                        flex-shrink: 0;
                    }
                }
            }

            .assetimage {
                img {
                    width: 100%;
                    background-color: #d9d9d9;
                }
            }

            .images {
                position: relative;
                gap: 20px;
                display: flex;
                flex-direction: column;

                .orderlineList,
                .projects {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 24px;
                }

                .loading {
                    position: absolute;
                    top: 100px;
                    left: 50%;
                    z-index: 1;
                    width: 48px;
                    height: 48px;
                    transform: translate(-50%, -50%);

                    path {
                        fill: #fff;
                    }
                }

                .rendering {
                    width: 100%;
    
                    img {
                        width: 100%;
                    }
                }
    
                .image {
                    width: 100%;
                    aspect-ratio: 1 / 1;
                    position: relative;
                    background: rgba(217, 217, 217, 0.20);
                    overflow: hidden;
    
                    img {
                        object-fit: cover;
                    }
    
                    .lazy-load-image-background {
                        width: 100%;
                        height: 100%;
                        justify-content: center;
                        align-items: center;
                    }
    
                    .points {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
        
                        .point {
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            border-radius: 100%;
                            background-color: #000;
                            transform: translate(-50%, -50%);
                            color: #fff;
                            text-align: center;
                            font-size: 13px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }

        &.orderline {
            .asset-details-overlay-content {
                flex-direction: column;
                height: 100%;
                align-items: center;

                .orderline-img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 0;
                    flex-shrink: 1;
                    flex-basis: auto;
                    width: 100%;

                    & > * {
                        max-height: 100%;
                    }

                    .turntable {
                        aspect-ratio: 1/1;
                        align-self: stretch;
                    }
                }

                .orderline-details {
                    flex-grow: 0;
                    flex-shrink: 0;
                    display: flex;
                    gap: 80px;

                    .project-images-list-actions button {
                        color: #fff;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
