.filter-presets {
    display: flex;
    flex: 1;

    > div {     
        display: flex;
        flex: 1 0 auto;
        gap: 8px;
        align-items: center;
    }

    .filter-preset-select {
        display: flex;
        flex: 1 0 auto;
        min-width: 300px;
    }
}