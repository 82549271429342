.statistics {
   
    .statistics-header {
        padding-top: 20px;

        h1 {
            font-size: 80px;
            font-style: normal;
            font-weight: 400;
            display: flex;
            line-height: 96.5%;
            align-items: flex-start;

            span {
                   font-family: Domaine Display;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 96.5%;

            }
        }
    }

    .ant-form-item-label  {
        display: none;
    }
}