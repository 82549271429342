#createapp {
    .designer-page {
        display: flex;
        flex-direction: column;

        .rendering-blocker {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.9);
            z-index: 100;

            div {
                width: 620px;
                position: absolute;
                right: 20px;
                bottom: 20px;
                text-align: right;

                p {
                    color: #454545;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 137%; /* 21.92px */
                    text-transform: uppercase;
                }

                h1 {
                    color: #000;
                    font-size: 120px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin: 0;
                    margin-bottom: 20px;
                }

                .button {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }

        .react-colorful {
            height: 240px;
            width: 385px;
        }
        .react-colorful__saturation {
            border-radius: 4px 4px 0 0;
        }
        .react-colorful__hue {
            height: 40px;
            border-radius: 0 0 4px 4px;
        }

        header {
            padding-left: 180px;
            margin-right: -20px;
            justify-content: space-between;

            div {
                display: flex;
                gap: 30px;
            }

            button {
                font-size: 15px;
                transition: all 0.25s ease-in-out;

                &.deselected {
                    font-weight: 400;
                    opacity: 0.2;
                }

                &.create {
                    background-color: #000;
                    color: #fff;
                    padding: 12px;
                    border-radius: 14px;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 110%; /* 13.2px */

                    svg {
                        margin: 0 0 0 10px;
                        color: #fff;
                    }
                }

                &.icon {
                    border-radius: 14px;
                    border: 1px solid #e6e6e6;
                    padding: 12px 8px;

                    svg {
                        margin: 0;
                    }
                }
            }
        }

        footer {
            border-top: 1px solid #e5e5e5;
            padding: 15px 20px;
            display: flex;
            height: 50px;
            justify-content: space-between;
            margin: 0 -20px;
            font-weight: 700;

            button {
                font-size: 16px;
                font-weight: 700;
                border: 0;
                background: none;
                padding: 0;
                transition: all 0.25s ease-in-out;
                align-items: baseline;

                svg {
                    margin-left: 5px;
                    margin-right: 0;
                }
            }

            svg {
                vertical-align: sub;
            }

            a {
                color: #7f7f7f;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 96.5%; /* 15.44px */
                margin-right: 20px;
            }
        }

        .designer-page-container {
            min-height: 0;
            flex-grow: 1;

            .loading {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1;
                width: 48px;
                height: 48px;
                transform: translate(-50%, -50%);
            }

            .no-project {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1;
                width: 200px;
                text-align: center;
                transform: translate(-50%, -50%);
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
            }
        }

        .image-selection {
            display: flex;
            flex-direction: column;
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 96.5%; /* 15.44px */

            h2 {
                font-family: PP Radio Grotesk;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 96.5%; /* 15.44px */
                margin-bottom: 30px;
            }

            .image-selection-footer {
                margin-top: 16px;
            }

            .image-selection-content {
                overflow: auto;
                flex-grow: 1;
                display: flex;
                flex-direction: column;

                .image-selection-header {
                    position: sticky;
                    top: 0;
                    background: #1a1a1a;
                    padding: 6px 0;
                    z-index: 2;
                }

                table {
                    width: 100%;
                    table-layout: fixed;
                }

                th {
                    color: #eee;
                    font-size: 11px;
                    font-style: normal;
                    text-align: left;
                    font-weight: 400;
                    line-height: 135%; /* 14.85px */
                }

                td {
                    vertical-align: top;
                }

                .divider {
                    overflow: hidden;
                    padding-bottom: 10px;
                }

                label {
                    font-weight: 400;
                    color: #7f7f7f;
                    display: block;
                    margin-top: 7px;
                    margin-bottom: 2px;
                }

                .variants>div, .formats>div {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    button {
                        border-radius: 52px;
                        border: 1px solid rgba(255, 255, 255, 0.3);
                        color: #fff;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 110%; /* 12.1px */
                        padding: 7px 16px;
                        margin-right: 5px;
                        cursor: pointer;
                        transition: all ease-in-out 0.25s;
                        justify-content: center;
                        height: 30px;
    
                        &.selected {
                            color: #000;
                            background-color: #fff;
                            border-color: #fff;
                        }

                        .warning {
                            display: block;
                            font-size: 8px;
                            color: #F00;
                        }
                    }
                }

                .filenames {
                    padding-right: 20px;

                    &>div {
                        margin-bottom: -16px;
                    }

                    .placeholder {
                        color: #fff;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 96.5%; /* 15.44px */
                        opacity: 0.3;
                    }
    
                    .error {
                        color: #ff0707;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 135%; /* 13.5px */
                        display: block;
                        margin-top: 0;
                        margin-bottom: 0;
                        height: 16px;
                        padding: 0;
                    }
    
                    input {
                        border-radius: 2px;
                        border: 1px solid rgba(255, 255, 255, 0.3);
                        color: #fff;
                        font-family: PP Radio Grotesk;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 96.5%; /* 15.44px */
                        height: 32px;
                        background: transparent;
                        width: 100%;
                        max-width: 600px;
                        padding: 0 6px;
                        display: block;
                        height: 30px;
                        margin: 0;

                        &:read-only {
                            opacity: 0;
                        }
    
                        &:focus {
                            outline: none;
                        }
                    }
                }

                .camera {
                    border-radius: 2px;
                    position: relative;
                    cursor: pointer;
                    transition: all ease-in-out 0.25s;
                    width: 170px;
                    height: 170px;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    border: 1px solid #c7c7c7;
                    flex-direction: column;
    
                    svg {
                        color: #fff;
                        margin: 0;
                    }

                    p {
                        color: #fff;
                        margin: 6px;
                    }

                    .buttons {
                        position: absolute;
                        bottom: 10px;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: #000;
                        border-radius: 20px;
                        display: flex;
                        flex-direction: row;
                        opacity: 0;
                        transition: opacity ease-in-out 0.25s;
    
                        button {
                            padding: 10px;
                            opacity: 0.7;
                            transition: opacity ease-in-out 0.25s;
    
                            svg {
                                color: #fff;
                                margin: 0;
                            }
    
                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
    
                    &:hover {
                        .buttons {
                            opacity: 1;
                        }
                    }

                    img {
                        opacity: 0.2;
                        transition: all ease-in-out 0.25s;
                        display: block;
                        object-fit: cover;
                    }

                    .checkmark {
                        position: absolute;
                        background: #000;
                        padding: 4px;
                        top: 0;
                        left: 0;
                        color: #fff;
                        border-bottom-right-radius: 4px;
                        opacity: 0;
                        transition: all ease-in-out 0.25s;
                        z-index: 1;
                    }

                    &.selected {
                        .checkmark {
                            opacity: 1;
                        }

                        img {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
