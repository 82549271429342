.hotspots-editor {

    .placementpoint-list {
        ul {
            li {
                &.active {
                    h4 {
                        color: rgb(56, 158, 13) !important;
                    }
                }
            }
        }
    }

    .ant-tabs {
        display: block;
        height: 100%;
        overflow: hidden;
    }

    .ant-tabs-content-holder {
        overflow-y: auto;
        height: calc(100% - 9em);
    }

    .hotspots-container {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        user-select: none;

        .hotspots {

            position: relative;
            //pointer-events: all;

            /*&.editing {
                pointer-events: none;
                .scale-rect-container {
                    pointer-events: all;
                }
            }*/

            img {
                height: 100%;
                width: auto;
                display: block;
                user-select: none;
                pointer-events: none;
            }

            .aspect-overlay {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.6);
                //  clip-path: polygon(0% 0%, 0% 100%, 25% 100%, 25% 0%, 75% 0%, 75% 100%, 100% 100%, 100% 0%);
                //clip-path: polygon(0% 0%, 100% 0%, 100% 25%, 0% 25%, 0% 75%, 100% 75%, 100% 100%, 0% 100%);
            }

            .hotspot-item,
            .scale-handle {
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                width: 10px;
                height: 10px;
                background-color: #4096ff;
                border-radius: 10px;
                opacity: 0;
                user-select: none;
                transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
                transform: translate(-5px,-5px) scale(1, 1);
                cursor: pointer;
                pointer-events: none;

                &.active {
                    opacity: 1;   
                    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;               
                    pointer-events: all;
                }

                &.selected {
                    background-color: rgb(56, 158, 13);
                    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;         
                    transform: translate(-5px,-5px) scale(1.4, 1.4);
                }

              
            } 
            
            .scale-rect-container {

                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;   
                z-index: 1;                  
                
                .scale-handle {
                    width: 15px;
                    height: 15px;
                    border-radius: 0;
                    //background-color: white;
                    background-color: rgb(56, 158, 13);
                    opacity: 1;
                    z-index: 20;  
                    transform: translate(-7.5px,-7.5px) scale(1, 1);   
                    user-select: none;
                    pointer-events: all;

                    &.dragging {
                        background-color: rgb(56, 158, 13);
                        opacity: 0.3;
                        transform: translate(-7.5px,-7.5px) scale(1.3, 1.3);   
                    }
                }
    
                .scale-rect {
                    position: absolute;
                    display: block;
                    background-color: rgb(56, 158, 13);
                    opacity: 0.2;
                    user-select: none;
                   // pointer-events: none;
                    z-index: 10;
                }
            }
        }
        
    }    

    .draggable {
        cursor: move;
        /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
    }

    .draggable:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }
}