.expression-editor {
    margin-bottom: 20px;
    display: flex;
    gap: 8px;
    align-items: center;

    button {
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.primary {
            svg {
                g {
                    fill: #FFF;
                }
            }
        }
    }




}